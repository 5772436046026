import React, { useEffect, useRef, useMemo, useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as d3 from "d3";

import styles from "assets/jss/linearChartstyle.js";
import styles2 from "./pie-chart.module.css";
import chartColors from "assets/jss/chartsColors";

const useStyles = makeStyles(styles);


const requiredSizeForXAxis = 30;

function getMaxTextSize(array) {
    return array.reduce((max, current) => {
        const tempSpan = document.createElement("span");
        tempSpan.style.fontSize = '1rem';
        tempSpan.style.visibility = "hidden"; // Make the element invisible
        tempSpan.innerText = current;
        document.body.appendChild(tempSpan);

        const rect = tempSpan.getBoundingClientRect();
        const width = rect.width;

        document.body.removeChild(tempSpan);
        return Math.max(max, width);
    }, 0)
}

function getMaxSize(array) {
    return array.reduce((max, current) => {
        return Math.max(max, current.length);
    }, 0)
}

export default function D3BarChart(props) {
    const classes = useStyles();
    const [heightDiv, setHeight] = useState(0)
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight,]);

    const { source, isPreview, width, height, requiresPercentage, requiresLegends, requiresLegends2, groupedDataKeys, percentageKeys, allGroups, series, percentageValues,  /* today,  */type, typeVal, currency, decimals } = props;

    const [MARGIN, setMargin] = useState({ top: 0, right: requiresLegends2 ? 0 : 4, bottom: 0, left: requiresLegends2 ? 50 : 30 });
    const ref = useRef(null);
    const linesRef = useRef(null);
    const dotsRef = useRef(null);
    /* const [isNan, setIsNan] = useState(false); */

    const [dataRep] = React.useState({ isLoading: true, data: source, scaledBy: undefined });
    const axesRef = useRef(null);
    const divRef = useRef(null);
    const boundsWidth = width - MARGIN.right - MARGIN.left;

    const maxLength = getMaxTextSize(allGroups);
    const boundsHeight = height - MARGIN.top - MARGIN.bottom - (heightDiv > 61 ? 61 : 0) - ((isNaN(allGroups[0]) && allGroups.length > 5) || getMaxSize(allGroups) > 10 ? (maxLength - 20) / Math.sqrt(2) : 0);
    useEffect(() => {
        if (windowSize[1] >= 900 || windowSize[1] <= 1200) {
            if (divRef.current.clientHeight > 61) {
                setHeight(70);
            } else {
                setHeight(60);
            }
        }
    }, [windowSize]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        setHeight(divRef?.current?.clientHeight);
    }, [divRef?.current?.clientHeight]);

    const capitalizeString = (name) => {
        return name.charAt(0).toUpperCase() + name.slice(1, name.length);
    }

    const yMaxValue = (data, keys, per = false) => {
        const allValues = [];
        if (per) {
            keys.map(key => data[key].map(val => {
                allValues.push(val);
                return val;
            }))
        } else {
            if (type === 3) {
                data.forEach(val => {
                    let valuesForKey = 0;
                    if (keys.length === 0) {
                        valuesForKey += val;
                    } else {
                        keys.map(key => valuesForKey += val[key] || 0);
                    }
                    allValues.push(valuesForKey);
                });
            }
            else {
                data.forEach(val => {
                    keys.map(key => allValues.push(val[key] || 0));
                });
            }
        }
        return Math.max(...allValues);
    };

    const ticksPercentajeValue = yMaxValue(percentageValues, percentageKeys, true) < 50 ? 5 : 10;

    // Y axis
    const yScaleLeft = useMemo(() => {
        return d3
            .scaleLinear()
            .domain([0, type === 3 && typeVal === 'percentage' ? 100 : yMaxValue(dataRep.data, groupedDataKeys) * 1.15 || 0])
            // .domain([0, yMaxValue(dataRep.data, groupedDataKeys) * 1.15 || 0])
            .range([boundsHeight, 0]);
    }, [dataRep.data, boundsHeight, groupedDataKeys]);

    const yScaleRight = useMemo(() => {
        if (currency[0] === '%') {
            return d3
                .scaleLinear()
                .domain([0, yMaxValue(dataRep.data, groupedDataKeys) * 1.15 || 0])
                .range([boundsHeight, 0]);
        } else {
            return d3
                .scaleLinear()
                .domain([0, yMaxValue(percentageValues, percentageKeys, true) * 1.15 || 0])
                .range([boundsHeight, 0]); // eslint-disable-next-line
        }
    }, [boundsHeight, percentageKeys]);


    // X axis
    const xScale = useMemo(() => {
        return d3.scaleBand()
            .domain(allGroups)
            .range([0, boundsWidth - requiredSizeForXAxis])
            .padding(0.05);  // eslint-disable-next-line
    }, [dataRep.data, boundsWidth]);

    // Color Scale
    var colorScale = d3.scaleOrdinal()
        .domain(groupedDataKeys)
        .range(chartColors.barChart);

    var colorScaleLines = d3.scaleOrdinal()
        .domain(percentageKeys)
        .range(chartColors.lineChart);

    // Render the X and Y axis using d3.js, not react
    useEffect(() => {
        const svgElement = d3.select(axesRef.current);
        svgElement.selectAll("*").remove();
        const xAxisGenerator = d3.axisBottom(xScale).tickFormat(d => d.replace('TOTAL>', ''));

        /* for (let index = 0; index < allGroups.length; index++) {
            if(isNaN(allGroups[index])){
                setIsNan(true);
                break;
            }
        } */
        if (isNaN(allGroups[0])) {
            svgElement
                .append("g")
                .attr("transform", `translate(${allGroups.length < 6 ? 0 : -8}, ${boundsHeight})`)
                .call(xAxisGenerator)
                .selectAll("text")
                .style("text-anchor", `${getMaxSize(allGroups) <= 10 && (allGroups.length < 6) ? "center" : "end"}`)
                .attr("transform", `rotate(${getMaxSize(allGroups) <= 10 && (allGroups.length < 6) ? 0 : -45})`);
        } else {
            svgElement
                .append("g")
                .attr("transform", "translate(0," + boundsHeight + ")")
                .call(xAxisGenerator);
        }

        const yAxisGeneratorLeft = d3.axisLeft(yScaleLeft).tickFormat(d => (currency[0] === '%' ? '' : currency) + (d === 0 ? currency[0] === '%' ? 0 : '-' : numberWithCommas(d)) + (currency[0] === '%' ? currency : ''));
        const yAxisGeneratorRight = d3.axisRight(yScaleRight).ticks(ticksPercentajeValue).tickFormat(d => (d.toFixed(d < 10 ? 1 : 0) + '%'));

        const posLastTick = yScaleLeft(yScaleLeft.ticks()[yScaleLeft.ticks().length - 1]);
        setMargin(prev => ({ ...prev, top: (((posLastTick < 18 && dataRep.data.scaledBy || typeVal == 'percentage') && typeVal !== 'no-margin') ? 14 - posLastTick : 0) }));
        svgElement.append("g").call(yAxisGeneratorLeft);
        if (currency[0] !== '%') {
            svgElement
                .append("g")
                .attr("transform", "translate(" + (boundsWidth - MARGIN.right - MARGIN.left + (requiresLegends2 ? 20 : 0)) + ",0)")
                .call(yAxisGeneratorRight); // eslint-disable-next-line
        }
    }, [xScale, yScaleLeft, boundsHeight]);

    function Rectangles({ group, subgroup, j }) {
        const [focused, setFocused] = useState(false);
        let bandwidth = xScale.bandwidth() / (type === 3 ? 1 : groupedDataKeys.length);
        if (focused) {
            ref.current.classList.add(styles2.hasHighlight2);
        }
        return (
            <g
                className={styles2.slice}
                onMouseEnter={() => {
                    if (ref.current && group[1] != 0) {
                        setFocused(true);
                        ref.current.classList.add(styles2.hasHighlight);
                        linesRef.current.classList.add(styles2.opacity);
                        dotsRef.current.classList.add(styles2.opacity);
                    }
                }}
                onMouseLeave={() => {
                    if (ref.current && group[1] != 0) {
                        setFocused(false);
                        ref.current.classList.remove(styles2.hasHighlight);
                        linesRef.current.classList.remove(styles2.opacity);
                        dotsRef.current.classList.remove(styles2.opacity);
                    }
                }}>
                <rect
                    key={j}
                    x={xScale(group.data.x) + (type === 3 ? 0 : (bandwidth * groupedDataKeys.indexOf(subgroup.key)))}
                    y={yScaleLeft(group[1])}
                    height={yScaleLeft(group[0]) - yScaleLeft(group[1])}
                    width={bandwidth}
                    fill={subgroup.key.includes('TOTAL>') || group.data.x.includes('TOTAL>') ? chartColors.total : colorScale(subgroup.key)}
                    opacity={0.9}
                >
                </rect>
                {
                    group[1] == 0 && type === 6 ?
                        <text
                            x={xScale(group.data.x) + (xScale.bandwidth() / 2)}
                            y={yScaleLeft(group[1]) + ((yScaleLeft(group[0]) - yScaleLeft(group[1])) / 2) - 4}
                            className="textOnChartPointBar"
                            textAnchor="middle"
                        >
                            0 %
                        </text> : null
                }
                {focused && group[1] != 0 ?
                    <text
                        x={xScale(group.data.x) + (xScale.bandwidth() / 2)}
                        y={yScaleLeft(group[1]) + ((yScaleLeft(group[0]) - yScaleLeft(group[1])) / 2) + 4}
                        className="textOnChartPointBar"
                        textAnchor="middle"
                        style={{ fill: subgroup.key.includes('TOTAL>') || group.data.x.includes('TOTAL>') ? 'white' : '' }}
                    >
                        {isNaN(group[1]) ? group[1] : (typeVal === 'percentage' ? parseFloat(group[1] - group[0]).toFixed(decimals || 2) + '%' : parseFloat(group[1] - group[0]).toFixed(decimals || 2) + ' ' + (dataRep?.data?.scaledBy === undefined ? '' : JSON.parse(dataRep?.data?.scaledBy || '{}')?.label))}
                    </text>
                    : null}
            </g>
        );
    }

    const rectangles = series.map((subgroup, i) => {
        return (
            <g key={i}>
                {subgroup.map((group, j) =>
                    <Rectangles subgroup={subgroup} group={group} j={j} />
                )}
            </g>
        )
    });

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function DataDot(props) {
        const { item, index, keyI } = props;
        const [focused, setFocused] = React.useState(false);

        const handleFocus = (state) => {
            setFocused(state);
        }

        const getDisplayableText = (itemx) => {
            if (typeof (itemx) === 'string') {
                return parseFloat(itemx).toFixed(decimals || 2) + "%";
            } else {
                return itemx.toFixed(decimals || 2) + "%";
            }
        };

        const areSame = percentageValues[keyI].every(element => element === percentageValues[keyI][0]);

        const positionX = xScale(dataRep?.data[index]?.x) + (xScale.bandwidth() / 2) + requiredSizeForXAxis + (requiresLegends2 ? 20 : 0); //width * 2 * ((index + 1) / 100) + MARGIN.left;
        // const positionY = height - (height * (item / (ticksPercentajeValue * 10)) - 20);
        const positionY = yScaleRight(item) + MARGIN.top;

        let rectSize = yScaleLeft(series[series.length - 1][index][1]);
        return (
            <g key={index} onMouseOver={() => handleFocus(true)} onMouseOut={() => handleFocus(false)}>
                {focused && (positionY - 20 > rectSize) && currency[0] === '%' || (type === 6 && focused) ? <line
                    x1={((percentageValues[keyI].length - 1 === index && areSame) ? positionX + 25 : positionX) - 18}
                    x2={((percentageValues[keyI].length - 1 === index && areSame) ? positionX + 25 : positionX) + 18}
                    y1={((percentageValues[keyI].length - 1 === index && areSame) ? positionY - 5 : positionY - 10) - 4}
                    y2={((percentageValues[keyI].length - 1 === index && areSame) ? positionY - 5 : positionY - 10) - 4}
                    style={{ stroke: 'white', 'stroke-width': 10, 'stroke-opacity': "0.8", 'stroke-linecap': "round" }} /> : null}
                <text
                    x={(percentageValues[keyI].length - 1 === index && areSame) ? positionX + 25 : positionX}
                    y={(percentageValues[keyI].length - 1 === index && areSame) ? positionY - 5 : positionY - 10}
                    textAnchor="middle"
                    className={(positionY - 20 > rectSize) && currency[0] !== '%' && type === 3 ? "textOnChartPointNewWhite" : "textOnChartPointNew"}
                    style={{ fill: (positionY - 20 > rectSize) && currency[0] === '%' ? 'black' : '' }}
                >
                    {focused ? getDisplayableText(item) : (percentageValues[keyI].length - 1 === index && areSame) ? getDisplayableText(item) : ""}
                </text>
                <circle
                    cx={positionX}
                    cy={positionY}
                    r={2}
                    fill="#fff"
                    strokeWidth={2}
                    stroke="#fff"
                    style={{ transition: "ease-out .1s", cursor: "pointer" }}
                />
                <circle
                    cx={positionX}
                    cy={positionY}
                    r={focused ? 4 : 2}
                    fill={colorScaleLines(keyI)}
                    strokeWidth={focused ? 2 : 0}
                    stroke="#fff"
                    style={{ transition: "ease-out .1s", cursor: "pointer" }}
                />
            </g >
        )
    }

    const renderLegends = () => {
        return (
            <div ref={divRef} className={classes.graphLegendsContainer} style={{ marginTop: heightDiv > 61 ? '-46px' : '' }}>
                <>
                    {
                        groupedDataKeys.map((element, index) => {
                            return (
                                <div key={index} className={classes.graphLegend}>
                                    <div className={classes.graphLegendVisualRepresentationNew} style={{ background: element.includes('TOTAL>') ? chartColors.total : colorScale(element), height: '10px', width: '10px' }} />
                                    <div>
                                        <Typography className={requiresLegends2 ? classes.graphLegendTextNew : ''} variant="subtitle2">{capitalizeString(element.replace('TOTAL>', ''))}</Typography>
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
                <>
                    {
                        percentageKeys.length === 0 ? null :
                            percentageKeys.map((element, index) => {
                                return (
                                    <div key={index} className={classes.graphLegend}>
                                        <div className={classes.graphLegendVisualRepresentationNew} style={{ background: colorScaleLines(element), height: '10px', width: '10px' }} />
                                        <div>
                                            <Typography className={requiresLegends2 ? classes.graphLegendTextNew : ''} >{capitalizeString(element.replace('PER>', ''))}</Typography>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </>
            </div>
        )
    }

    const linePath = (key) => d3
        .line()
        .x((d) => xScale(dataRep?.data[d.index]?.x) + (xScale.bandwidth() / 2) + requiredSizeForXAxis + (requiresLegends2 ? 20 : 0))
        .y((d) => yScaleRight(d.data) + MARGIN.top /* height - (height * (d.data / (ticksPercentajeValue * 10)) - 20) */)
        .curve(d3.curveMonotoneX)(percentageValues[key].map((v, i) => ({ name: 'per', data: v, index: i })));

    const dots = percentageKeys.map(key => percentageValues[key].map((item, index) => <DataDot item={item} index={index} keyI={key} requiresPercentage={requiresPercentage} />));

    const generateTicks = () => {
        const ticks = [];
        if (currency[0] !== '%') {
            for (let index = 0; index <= ticksPercentajeValue; index++) {
                let item = yScaleRight.ticks()[index * (ticksPercentajeValue === 5 ? 2 : 1)];
                ticks.push(<path strokeWidth={1.4} fill="none" stroke={'currentColor'} stroke-width={1} stroke-dasharray={'5,5'} opacity={0.2} d={lines([{ x: MARGIN.left, y: yScaleRight(item) + MARGIN.top }, { x: boundsWidth - requiredSizeForXAxis + MARGIN.left, y: yScaleRight(item) + MARGIN.top }])} />)
            }
        } else {
            for (let index = 0; index <= yScaleLeft.ticks().length; index++) {
                let item = yScaleLeft.ticks()[index];
                ticks.push(<path strokeWidth={1.4} fill="none" stroke={'currentColor'} stroke-width={1} stroke-dasharray={'5,5'} opacity={0.2} d={lines([{ x: MARGIN.left, y: yScaleRight(item) + MARGIN.top }, { x: boundsWidth - requiredSizeForXAxis + MARGIN.left, y: yScaleRight(item) + MARGIN.top }])} />)
            }
        }
        return ticks;
    }

    const generatePaths = () => percentageKeys.map(key => <path strokeWidth={2} fill="none" stroke={colorScaleLines(key)} d={linePath(key)} />);

    const lines = (path) => d3
        .line()
        .x((d) => d.x)
        .y((d) => d.y)
        .curve(d3.curveLinear)(path);

    if (source === undefined) return <></>;

    return (
        <div style={{ backgroundColor: 'white', padding: requiresLegends ? (dataRep.scaledBy ? '25px 0px' : '15px 0px 25px 0px') : (requiresLegends2 ? '8px 15px 0px 0px' : '5px 0px') }}>
            <svg viewBox={`0 0 ${width} ${isPreview ? height : height + 20} `} style={{ background: '#ffffff', transform: !isNaN(allGroups[0]) ? '' : allGroups.length <= 1 ? '' : '' }}>
                {(((requiresLegends || requiresLegends2) && dataRep.data.scaledBy) || typeVal === 'percentage') ? <text textAnchor="left" x={typeVal === 'percentage' ? 12 : JSON.parse(dataRep.data.scaledBy).xOffset} y="-4" transform={`translate(${requiresLegends2 ? (type === 3 && typeVal === 'percentage' ? 23 : 19) : 0}, 10)`}>{typeVal === 'percentage' ? '%' : JSON.parse(dataRep.data.scaledBy).label} </text> : null}
                {/* {(requiresLegends && percentageKeys.length > 0) ? <text textAnchor="left" x={boundsWidth} y="-4" transform={`translate(${ 12}, 10)`}>%</text> : null} */}
                {generateTicks()}
                <g
                    width={boundsWidth}
                    height={boundsHeight}
                    transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
                    className={styles2.container}
                    ref={ref}
                >
                    {rectangles}
                </g>
                <g
                    className={"axis xAxis " + (requiresLegends2 ? "newCharts " : "newChartsBar ") + (((dataRep.data.length) && (undefined !== dataRep.data[0].variant) && (dataRep.data[0].variant === "trimester")) ? "trimester" : "")}
                    width={boundsWidth}
                    height={boundsHeight}
                    ref={axesRef}
                    transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
                />
                <g ref={linesRef} >
                    {generatePaths()}
                </g>
                <g ref={dotsRef}>
                    {dots}
                </g>
            </svg>
            {
                isPreview ? null : (requiresLegends ? renderLegends() : null)
            }
            {
                requiresLegends2 ? renderLegends() : null
            }
        </div>
    );
}
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import FeedsCard from "components/flujograma/FeedsCard.js";
import SlideshowCard from "components/flujograma/SlideshowCard.js";
import D3LinearChart from "components/flujograma/D3LinearChart.js";
import BaseCard from "components/flujograma/BaseCard.js";
import YoutubeEmbed from "components/general/YoutubeEmbed.js";
import LoadingIndicator from "components/general/LoadingIndicator.js";

import styles from "assets/jss/primaryScreenstyle.js";

import axios from 'axios';
import ApiURLs from "config/ApiURLs.js";
import { Link } from "react-router-dom";
import BaseScreenIndicadores from "./indicadores/BaseScreen.jsx";
import D3Bar from "components/indicadores/D3Bar.jsx";
import SlideshowCardNew from "components/indicadores/SlideshowCard.jsx";
import { D3PieChart } from "components/indicadores/D3PieChart.jsx";

const useStyles = makeStyles(styles);

export default function PrimaryScreenGeneral(props) {
    //const appContext = React.useContext(AppContext);
    //const rootRef = React.useRef(null);
    let baseYear = (new Date().getFullYear()) - 1;
    const classes = useStyles();
    //const [searchValue, setSearchValue] = React.useState('');

    const [videoList, setVideoList] = React.useState({ isLoading: true, data: { 'name': '...' } /*remove fakeData once backend is ready*/ });
    const [mainChart, setMainChart] = React.useState({ isLoading: true, data: {} });
    const [chart1, setChart1] = React.useState({ isLoading: true, data: {} });
    const [chart2, setChart2] = React.useState({ isLoading: true, data: {} });
    const [screenSize, setScreenSize] = React.useState(getCurrentDimension());

    React.useEffect(() => {
        document.title = 'Datos de libre acceso de la Justicia Criminal - Guatemala';
    }, [window.location.href]);

    React.useEffect(() => {
        axios.get(ApiURLs.baseURL + 'website/video-list?id=vl001').then((data) => {
            setVideoList({ isLoading: false, data: data.data });
        });

        axios.get(ApiURLs.baseURL + 'website/chart?id=C-GLOBAL-004').then((data) => {
            if (data.data.error === true) {

            } else {
                setMainChart({ isLoading: false, data: data.data });
            }
        });

        axios.get(ApiURLs.baseURL + 'website/chart?id=II-PIE-003&year=2023').then((data) => {
            console.log(data, 'bar3');
            if (data.data.error === true) {
                console.log('BAR 3 ERROR');
            } else {
                setChart1({ isLoading: false, data: data.data });
            }
        });

        axios.get(ApiURLs.baseURL + 'website/chart?id=SP-BAR-001').then((data) => {
            console.log(data, 'bar1');
            if (data.data.error === true) {
                console.log('BAR 1 ERROR')
            } else {
                setChart2({ isLoading: false, data: data.data });
            }
        });
    }, []);

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    React.useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    React.useEffect(() => {
        // Create meta elements
        const cacheControlMeta = document.createElement('meta');
        cacheControlMeta.httpEquiv = 'cache-control';
        cacheControlMeta.content = 'no-cache';

        const expiresMeta = document.createElement('meta');
        expiresMeta.httpEquiv = 'expires';
        expiresMeta.content = '0';

        const pragmaMeta = document.createElement('meta');
        pragmaMeta.httpEquiv = 'pragma';
        pragmaMeta.content = 'no-cache';

        // Add meta elements to the document head
        document.head.appendChild(cacheControlMeta);
        document.head.appendChild(expiresMeta);
        document.head.appendChild(pragmaMeta);

        // Cleanup: remove meta elements when the component unmounts
        return () => {
            document.head.removeChild(cacheControlMeta);
            document.head.removeChild(expiresMeta);
            document.head.removeChild(pragmaMeta);
        };
    }, []);

    /* const handleChange = (newValue) => {
        setSearchValue(newValue);
    }; */
    return (
        <BaseScreenIndicadores primaryHeader bodyColor="#f2f3f6">
            <div>
                <Typography variant="h1" className={classes.title}>Datos de libre acceso de la Justicia Criminal - Guatemala</Typography>
                <div className={classes.subtitleContainer}>
                    <Typography variant="h2" className={classes.subtitle}>Hacia un sistema de justicia transparente, eficiente y digital.</Typography>
                </div>
            </div>
            <Grid container>
                <Grid item xs={12} sm={12} md={7} style={{ marginBottom: '1.2rem', minHeight: '270px'}}>
                    <BaseCard height="100%">
                        <YoutubeEmbed embedId="Fqa-s5hJOug" />
                    </BaseCard>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    {
                        chart1.isLoading ? <LoadingIndicator /> : <SlideshowCardNew slideElements={[{ main: <D3PieChart width={700} height={(screenSize.width < 1270 && screenSize.width >= 960) ? (460 + (90 * ((1270 - screenSize.width) / 310))) : 440} source={chart1.data.data} requiresLegends2 type={3} portadaScreen />, title: 'Distribución del presupuesto de gastos de las instituciones del sector justicia', subtitle: 'Año 2024', chartId: 'II-PIE-003&year=2024', displayonflujograma: chart1.show_on_flujogram }]} />
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={6} style={{ marginBottom: '1rem', height: (screenSize.width < 960 && screenSize.width >= 600) ? '350px' : '300px' }}>
                    <BaseCard height="100%">
                        <Link to={"/flujograma/flujograma/" + baseYear}>
                            <img src='/history/animated.gif' style={{ width: "100%", height: "100%" }} alt="Flujograma de la Justicia Criminal" />
                        </Link>
                    </BaseCard>
                </Grid>
                <Grid item xs={12} sm={12} md={6} style={{ marginBottom: '1rem', minHeight: '300px' }}>
                    {
                        chart2.isLoading ? <LoadingIndicator /> : <SlideshowCardNew height={300} slideElements={[{ main: <D3Bar width={700} height={(screenSize.width < 1270 && screenSize.width >= 960) ? (245 + (90 * ((1270 - screenSize.width) / 310))) : 240} source={chart2.data.data} requiresLegends2 type={3} />, title: 'Privados de libertad por situación jurídica', subtitle: 'Años 2014-2024', chartId: 'SP-BAR-001' }]} />
                    }
                </Grid>
            </Grid>
        </BaseScreenIndicadores>
    )
}
